
* {
    font-size: 14px;
    font-family: "Noto Sans", sans-serif;
}

input {
    width: 100%;
    width: calc(100% - 32px);
    max-width: 368px;
    padding: 16px;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #ddd;
    font-size: 14px;
    margin-bottom: 16px;
}

input:focus {
    background-color: #fcfcfc;
}

button {
    width: 100%;
    max-width: 400px;
    padding: 16px;
    background-color: #f8f8f8;
    border-radius: 8px;
    border: 1px solid #dddddd;
    margin-bottom: 16px;
}

.go-to-login-text {
    width: 100%;
    max-width: 400px;
    margin-bottom: 16px;
    border-radius: 0;
    border: white;
    background-color: white;
    text-decoration-line: underline;
}

button p {
    margin: 0;
    line-height: 24px;
}

.full-width {
    width: 100%;
}

.content-container {
    width: 80%;
    max-width: 368px;
    padding: 16px;
    margin: 0px auto;
}

.terms-outer-container {
    width: 100%;
    max-width: 400px;
    margin-bottom: 16px;
}

.terms-outer-container input {
    float: left;
    width: 40px;
    margin: 0;
    margin-top: 5px;
}

.terms-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
}

.terms-container p {
    margin-right: 5px;
    margin-top: 0px;
    margin-bottom: 5px;
}

.info-text {
    width: 100%;
    width: calc(100% - 32px);
    padding: 16px;
    max-width: 400px;
    text-align: center;
}

.all-container {
    width: 100%;
    min-height: 100vh;
    overflow: auto;
}

.upper-third {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00794f;
}

.upper-third .title-container {
    width: 100%;
    width: calc(100% - 32px);
    padding: 16px;
    max-width: 400px;
    text-align: center;
    margin-bottom: 16px;
}

.upper-third .title-container .title-image-container {
    background: #ffffff;
    border-radius: 10%;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 16px;
    overflow: hidden;
}

.upper-third .title-container .title-image-container .title-image {
    width: 100px;
}

.upper-third .title-container h1 {
    color: #ffffff;
    font-size: 32px;
    margin: 0;
}

.lower-two-thirds {
    width: 100%;
    text-align: center;
}

.bottom-placeholder {
    width: 100%;
    height: 100px;
}

.language-container {
    width: calc(100% - 32px);
    max-width: 368px;
    padding: 16px;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #ddd;
    font-size: 14px;
    margin-bottom: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
}

.language-flag {
    height: 30px;
    margin-right: 16px;
    border: 1px solid #dddddd;
}

.language-label {
    color: #333;
    margin: 0;
}

.language-container-list {
    position: fixed;
    padding: 8px;
    top: 0;
    right: 0;
    background-color: #ffffff;
    border-left: 1px solid #f8f8f8;
    height: 100%;
    overflow: auto;
}

.language-selection-icon {
    position: absolute;
    right: 16px;
    color: #333333;
    font-size: 20px;
    margin: 0;
}

.language-container-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #ffffff;
    border-bottom: 1px solid #f8f8f8;
    cursor: pointer;
    margin-top: 8px;
    padding-bottom: 8px;
}

.language-container-list-item .language-label {
    color: #333;
}

.download-images{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.download-link-image{
    width: 150px;
    margin: 5px;
}

.answer-button{
    width: 100%;
    max-width: 332px;
    height: 50px;
    margin-top: 8px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 6px;
    box-shadow:  0 2px 4px 0 rgba(0,0,0,0.1);
    transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1), transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.answers-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    min-height: 264px;
}

.button-container-audio{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 130px;
    width: 130px;
    background-color: #ffffff;
    border-radius: 75px;
    cursor: pointer;
    border: 2px solid #64d950;
    padding: 0;
    color: inherit;
    font: inherit;
    outline: inherit;
}

.button-container-audio:hover{
    background-color: #54c940;
}

.button-container-audio.playing{
    background-color: #64d950 !important;
}

.button-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 100%;
    max-width: 332px;
    background-color: #64d950;
    border-radius: 6px;
    cursor: pointer;
    border: none;
    padding: 0;
    box-shadow:  0 2px 4px 0 rgba(0,0,0,0.1);
    transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1), transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.button-container:focus {
    outline: none;
}

.button-container:hover {
    box-shadow:  0 4px 6px 0 rgba(0,0,0,0.4);
}

.button-container:active{
    background-color: #54c940;
    box-shadow:  none;
}

.button-text {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
}

.card-move-container{
    height: 100%;
    width: 100%;
}

.card-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    border-radius: 8px;
}

.progress-outer-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 3vh;
    width: 100%;
    position: relative;
}

.progress-bottom-container{
    width: 100%;
    height: 3vh;
    background-color: #f8f8f8;
}

.progress-time-container{
    position: absolute;
    width: 50px;
    height: 50px;
    background: #fff;
    border: 2px solid #64d950;
    border-radius: 27px;
    top: 50%;
    margin-top: -25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.standing-outer-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 12%;
    width: 100%;
    padding-top: 16px;
}


.standing-outer-container .home-user{
    flex: 3;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.standing-outer-container .standings{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 2;
    height: 100%;
}

.standing-outer-container .guest-user{
    flex: 3;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.standing-outer-container .trikot{
    height: 100px;
}

.standing-outer-container .username{
    font-size: 14px;
    margin-top: 2px;
}

.standings-text{
    margin: 0;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
}

.online-state-container {
    height: 25%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.online-bubble {
    height: 1.3vh;
    width: 1.3vh;
    margin-left: 0.5vh;
    margin-right: 0.5vh;
    border-radius: 1vh;
}
.online {
    background-color: #5aaa13;
}
.disabled {
    background-color: #bbbbbb;
}
.low {
    background-color: #ffcc00;
}
.offline {
    background-color: #b90000;
}

.word-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    flex: 1;
}

.word-image-container {
    height: 40vh;
    width: calc(100% - 32px);
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.word-translation-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.7);
}

.word-translation-text {
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

.word-text {
    color: #000000;
    font-size: 5vh !important;
    font-weight: bold;
    text-align: center;
}

.language-test {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.language-test-inner-container {
    height: 100%;
    width: 100%;
    max-width: 700px;
    max-height: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding-top: 24px;
}

.get-ready-container{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.get-ready-circle{
    width: 50%;
    max-width: 200px;
    margin-top: 30px;
    position: relative;
}

.get-ready-time-text{
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -40px;
    width: 80px;
    text-align: center;
    line-height: 40px;
    font-size: 36px;
    font-weight: bold;
}

.start-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    height: 100%;
}

.start-container-top{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.learnmatch-logo{
    width: 50%;
    max-width: 200px;
}

.match-start-image-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.match-start-image-container .match-start-image-container-inner{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.match-start-image-container p{
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    color: #212121;
}

.match-start-image-container p small{
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    color: #616161;
}

.match-start-trikot-image-container {
    flex: 1;
    display: flex;
    margin: 15px;
    padding: 15px;
    background: #f9f9f9;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
}

.match-start-trikot-image-container.active {
    border: 3px solid #64d950;
}

.match-start-trikot-image {
    width: 100%;
    max-width: 200px;
}

.match-start-vs {
    font-size: 18px;
    font-weight: bold;
}

.start-button-container{
    width: calc(100% - 32px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.vs-container{
    display: flex;
    align-self: stretch;
    align-items: flex-end;
}

.content-container-exercise{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.match-outer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    height: 100vh;
    width: 100%;
}

.match-end-result-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    flex-grow: 1;
    height: 100vh;
}

.match-end-result-container-top {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-bottom-color: black;
    border-bottom-width: 2px;
}

.match-end-result-container-bottom {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.match-end-scores-container {
    display: flex;
    align-self: stretch;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.match-end-player-container {
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
    max-width: 200px;
    margin-right: 15px;
    margin-left: 15px;
    padding: 20px;
    background: #f8f8f8;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
}

.match-end-image-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.match-end-image-container .match-end-image-container-inner{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
}

.match-end-trikot-image-container {
    flex: 1;
    display: flex;
    margin: 15px;
    padding: 15px;
    background: #f9f9f9;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
}


.match-end-trikot-image-container.active {
    border: 3px solid #64d950;
}

.match-end-image-container p{
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    color: #212121;
}

.match-end-image-container p small{
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    color: #616161;
}

.match-end-image-container p.match-end-player-points{
    font-size: 16px;
    font-weight: bold;
    margin-top: 4px;
}

.match-end-image-container p.active{
    color: #64d950;
    font-weight: bold;
}

.match-end-trikot-image {
    width: 100%;
    max-width: 200px;
}

.motivating-message-top {
    font-size: 16px;
    font-weight: normal;
    color: #616161;
    text-align: center;
    max-width: 300px;
}

.motivating-message-bottom {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: normal;
    color: #616161;
    text-align: center;
    max-width: 300px;
}

.motivating-message-course{
    margin-bottom: 4px;
    margin-top: 0px;
    font-size: 24px;
    font-weight: bold;
    color: #616161;
    text-align: center;
    max-width: 300px;
}

.match-end-winner{
    background: #295921;
    position: relative;
}

.success{
    height: 14vh;
    max-height: 130px;
}

.match-end-winner:after{
    content: "Winner!";
    position: absolute;
    right: -20px;
    top: -10px;
    z-index: 5;
    font-size: 2.5vh;
    transform: rotate(20deg);
    color: white;
    font-weight: bold;
    display: block;
    background: red;
    padding: 5px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-animation: fadein 1s;
    -moz-animation: fadein 1s;
    -ms-animation: fadein 1s;
    -o-animation: fadein 1s;
    animation: fadein 1s;
}

.button-with-message-container{
    width: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.match-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    height: 80%;
    width: calc(100% - 32px);
    padding: 16px;
    background: #f8f8f8;
    overflow: hidden;
}
